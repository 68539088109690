import React from "react";
import { Menu, Sidebar } from "react-pro-sidebar";
import SidebarLogo from "../utility/sidebar/SidebarLogo";
import SidebarItem from "../utility/sidebar/SidebarItem";
import DateAndDay from "../utility/DateAndDay";
import { appName } from "../../App";
const HomeSidebar = ({ isPositionFixed = true, refScroll }) => {
  const headerItem = [
    {
      title: "الرئيسية",
      path: "/",
      isLink: true,
    },
    {
      title: `عن ${appName}`,
      path: refScroll[0],
      isLink: false,
    },
    {
      title: "لوحات التحكم",
      path: refScroll[1],
      isLink: false,
    },
    {
      title: "مايميزنا",
      path: refScroll[2],
      isLink: false,
    },

    {
      title: "الاشتراكات",
      path: refScroll[3],
      isLink: false,
    },
    {
      title: "تواصل معنا",
      path: refScroll[4],
      isLink: false,
    },
  ];
  return (
    <Sidebar
      rtl={true}
      className="border-0"
      rootStyles={{
        position: isPositionFixed ? "fixed" : "relative",
        height: "100%",
        overflowY: "auto !important",
        Zndex: 3,
        top: 0,
        userSelect: "none !important",
      }}
    >
      <div className=" h-100 d-flex justify-content-between flex-column ">
        <Menu>
          <SidebarLogo />

          {headerItem.map((item, i) => (
            <SidebarItem
              title={item.title}
              icon={" "}
              iconActive={" "}
              onClick={item.isLink ? null : item.path}
              path={item.isLink ? item.path : null}
            />
          ))}
        </Menu>
        <div className="mt-5">
          <div className="my-3">
            <DateAndDay />
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default HomeSidebar;
