import React from "react";
import TitleHeadSpecialHomePage from "./TitleHeadSpecialHomePage";
import SubscriptionsCard from "../Subscriptions/SubscriptionsCard";
import { useNavigate } from "react-router-dom";
const SubscriptionsHome = () => {
  const navigation = useNavigate();
  const myDataPackages = [
    {
      price: "300",
      month: "3 شهور",
      AdvandagesItems: [
        " توفير الدعم الفني اللازم",
        " التقييم وتحسين التجربة بالكامل",
      ],
    },
    {
      price: "1000",
      month: "سنوياً",
      AdvandagesItems: [
        " توفير الدعم الفني اللازم",
        " التقييم وتحسين التجربة بالكامل",
        " البحث ف السوق مع العميل",
      ],
    },
    {
      price: "500",
      month: "6 شهور",
      AdvandagesItems: [
        " توفير الدعم الفني اللازم",
        " التقييم وتحسين التجربة بالكامل",
      ],
    },
  ];
  return (
    <div className="container  p-3 p-md-5" id="packages-homepage">
      <TitleHeadSpecialHomePage title={"الاشتراكات"} />
      <div className="row gy-4">
        {myDataPackages.map((item, i) => (
          <div className="col-12 col-sm-6 col-lg-4" key={i}>
            <SubscriptionsCard
              price={item.price}
              month={item.month}
              AdvandagesItems={item.AdvandagesItems}
              onclick={() =>
                navigation("/subscription", {
                  state: { myData: item },
                })
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubscriptionsHome;
