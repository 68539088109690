import React from "react";

const SidebarLogo = () => {
  return (
    <div
      style={{ width: 100, height: 100 }}
      className="w-100 d-flex justify-content-center align-items-center p-2"
    >
      <img
        src={`../../../../../images/logo.png`}
        alt="logo"
        style={{ maxWidth: "85%", maxHeight: "100%" }}
      />
    </div>
  );
};

export default SidebarLogo;
