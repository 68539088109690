import React from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

const Footer = () => {
  const dataCepanel = [
    "لوحة تحكم مديرالنظام",
    "لوحة تحكم للمدير",
    "لوحة تحكم للوكيل",
    "لوحة تحكم للعميل",
  ];
  const dataMedia = ["logo-twitter", "logo-facebook", "mail", "call"];
  let dataNow = new Date();
  return (
    <div
      className="p-3 mt-4 text-white"
      style={{ backgroundColor: "var(--primary-color)" }}
    >
      <div className="container">
        <div className="d-flex justify-content-between flex-column flex-sm-row">
          {/* col One */}
          <div className="d-flex align-items-center justify-content-center flex-column">
            <div
              className="bg-white p-2 rounded-3 d-flex align-items-center justify-content-center"
              style={{ width: "100px", height: "60px" }}
            >
              <img
                src="../../../images/logo.png"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
                alt="logo"
              />
            </div>
            <div className="nav nav-bar mt-3 d-flex flex-column">
              {dataCepanel.map((item, i) => (
                <Fade bottom key={i}>
                  <li className="my-2">
                    <span>
                      <img
                        src="../../images/icon-goal-homepage-white.png"
                        alt="icon-goal"
                        className="ms-2"
                        style={{
                          width: "25px",
                          height: "25px",
                        }}
                      />
                    </span>
                    <span className="fw-bold text-sub-title text-white text-opacity-75">
                      {item}
                    </span>
                  </li>
                </Fade>
              ))}
            </div>
          </div>
          {/* col Two */}
          <div className=" mt-3 mt-md-0 d-flex align-items-center justify-content-center flex-column">
            <div className="fw-bold mb-3 text-sub-title">
              نسعد بتواصلكم معنا
            </div>
            <div className="style-footer-media">
              {dataMedia.map((item, i) => (
                <Fade bottom key={i}>
                  <Link
                    className={`style-footer-media-link style-footer-media-link-${
                      i + 1
                    }`}
                  >
                    <ion-icon name={item}></ion-icon>
                  </Link>
                </Fade>
              ))}
            </div>
          </div>
          {/* col Three */}

          {/* <div className="col-12 col-md-6">three</div> */}
        </div>
        <div className="mt-3 mt-sm-5  text-center fw-bold">
          جميع الحقوق محفوظة © {dataNow.getFullYear()}
        </div>
      </div>
    </div>
  );
};

export default Footer;
