import React from "react";
import TitleHeadSpecialHomePage from "./TitleHeadSpecialHomePage";
import Fade from "react-reveal/Fade";

const SectionBeneficiaries = () => {
  const dataBeneficiaries = [
    {
      icon: "../../images/Beneficiaries-01.png",
      title: "نعزز تواصل مع العملاء ونعزز تجربتهم معنا",
    },
    {
      icon: "../../images/Beneficiaries-02.png",
      title: "نساهم في تعزيز رضا العملاء وزيادة ولائهم",
    },
    {
      icon: "../../images/Beneficiaries-03.png",
      title: "نساعد في تعزيز المبيعات من خلال متابعة العروض والفرص",
    },
    {
      icon: "../../images/Beneficiaries-04.png",
      title: "نمكنك من استخدام البيانات لاتخاذ قرارات استراتيجية أفضل",
    },
  ];
  return (
    <div className="container p-3 p-md-5 " id="Beneficiaries-homepage">
      <TitleHeadSpecialHomePage title={"المميزات"} />
      {/* col one */}
      <div className="row gy-4 mt-0 mt-md-5">
        {dataBeneficiaries.map((item, i) => (
          <Fade bottom key={i}>
            <div className="col-6 col-lg-3">
              <div className="position-relative d-flex justify-content-center">
                <img
                  src="../../images/bg-homepage-blob.png"
                  className="style-blob-homepage"
                  alt="homepage-blob"
                  style={{ width: 100 }}
                />
                <div
                  className="position-absolute"
                  style={{
                    top: "25px",
                  }}
                >
                  <img
                    // src={"../../images/icon-homepage-timer.png"}
                    src={item.icon}
                    className="style-icons-homepage"
                    alt="homepage-blob"
                  />
                </div>
              </div>
              <div className="text-center mt-4 fw-bold text-title-icons-blobs">
                {item.title}
              </div>
            </div>
          </Fade>
        ))}
      </div>
    </div>
  );
};

export default SectionBeneficiaries;
